import { Divider } from '@mybridge/ui/divider';
import { Stack, HStack } from '@mybridge/ui/layout';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@mybridge/ui/modal';
import { Text } from '@mybridge/ui/text';
import { SlideFade } from '@mybridge/ui/transitions';
import { triggerFetchPosts } from 'packages/mybridgev4/store/actions/post.actions';
import { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { PostFormBody } from 'v4/components/post-form/body';
import {
  PostFormContext,
  usePostFormContext,
} from 'v4/components/post-form/context';
import { PostFormFooter } from 'v4/components/post-form/footer';
import { PostFormHeader } from 'v4/components/post-form/header';
import { PostBoxContext } from 'v4/components/post/context';
import { PostVisibilityMenu } from 'v4/components/post-form/post-visibility-menu';

export const PostBoxEditModal = ({ ...props }) => {
  const dispatch = useDispatch();

  const { post, refetch, editPostModal } = useContext(PostBoxContext);

  const refetchAll = () => {
    refetch?.();
    editPostModal?.onClose?.();
  };

  const [openPostModal, setOpenPostModal] = useState(false);
  const postFormContext = usePostFormContext({
    post,
    refetchAll,
  });
  const {
    gifs,
    images,
    resetForm,
    videos,
    mode,
    companyId,
    currentPostId,
    visibility,
    formContainerRef,
    expanded,
    feelings,
    tagged,
    setMultiPost,
    multiPost,
    text,
  } = postFormContext ?? {};

  const [editingIndex, setEditingIndex] = useState(0);

  useEffect(() => {
    if (post) {
      const array = [{ ...post, mode: 'edit' }];
      if (post.child_posts && post.child_posts.length) {
        post.child_posts.forEach((post) => {
          array.push({ ...post, mode: 'edit' });
        });
      }
      setMultiPost(array);
    }
  }, [post, setMultiPost]);

  const AddHeaderField = () => {
    setMultiPost([
      ...multiPost,
      {
        text,
        gifs,
        images,
        videos,
        tagged,
        feelings,
        mode,
        companyId,
        id: currentPostId,
        visibility,
      },
    ]);
    resetForm();
  };

  return (
    <PostFormContext.Provider value={{ ...(postFormContext ?? {}) }}>
      <Modal size="2xl" {...props}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Text>Edit Post</Text>
            <ModalCloseButton />
          </ModalHeader>
          <ModalBody>
            <Stack
              ref={formContainerRef}
              minH={expanded ? '150px' : '40px'}
              transition="all 0.2s"
            >
              <Stack w="100%">
                <PostFormHeader
                  multiPost={multiPost}
                  editingIndex={editingIndex}
                  editingIndexHandler={(index) => setEditingIndex(index)}
                  isPostModalOpen={openPostModal}
                />
                <SlideFade in={expanded}>
                  {expanded ? (
                    <PostFormBody
                      editingIndex={editingIndex}
                      w="100%"
                      overflow={expanded ? '' : 'hidden'}
                    />
                  ) : (
                    ''
                  )}
                </SlideFade>
              </Stack>
              {expanded ? <Divider /> : ''}
              <HStack mb={5}>
                <PostVisibilityMenu
                  handleClose={() => setOpenPostModal(false)}
                  addHeaderField={AddHeaderField}
                />
              </HStack>
              {expanded ? (
                <SlideFade in={expanded}>
                  <Stack>
                    <PostFormFooter />
                  </Stack>
                </SlideFade>
              ) : (
                ''
              )}
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </PostFormContext.Provider>
  );
};
